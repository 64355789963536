$base:#002355;
$baseHover:#000739;
$color: #f5f5f5;
$color1: #2f54eb;
$color2: #a0d911;
$color3: #595959;
$bgcolor:#fff;
$textcolor: #434343;


//   Applicant ui

.applicant-ui-wrapper {
    h3 {
        color: $base;
        font-size: 24px !important;
    }

    .personel-infos { 
        border-radius: 7px;
        background: $bgcolor;        
        // padding: 15px !important;

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }

        .image-wrapper {
            height: 150px;

            img {
                margin-top: 12px;
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 2px;
            }
        }

        .personel-text {
            p {
                color: $base;
                font-weight: 600;
                margin-bottom: 2px;

                span {
                    margin: 0 5px 0 5px !important;
                    color: $textcolor;
                    font-weight: 400 !important
                }
            }
        }
    }

    .passport-infos {        
        border-radius: 7px;
        background: $bgcolor;        
        padding: 5px 15px 5px 15px !important;
        margin-bottom: 8px;

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }

        .passport-text {
            p {
                color: $base;
                font-weight: 600;
                margin-bottom: 2px;

                span {
                    margin: 0 5px 0 5px !important;
                    color: $textcolor;
                    font-weight: 400 !important
                }
            }
        }
    }

    .diplom-infos {        
        border-radius: 7px;
        background: $bgcolor;        
        padding: 5px 15px 5px 15px !important;
        margin-bottom: 8px;

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }

        .diplom-text {
            p {
                color: $base;
                font-weight: 600;
                margin-bottom: 2px;

                span {
                    margin: 0 5px 0 5px !important;
                    color: $textcolor;
                    font-weight: 400 !important
                }
            }
        }
    }

    .education-infos {        
        border-radius: 7px;
        background: $bgcolor;        
        padding: 5px 15px 5px 15px !important;

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }

        .education-text {
            p {
                color: $base;
                font-weight: 600;
                margin-bottom: 2px;

                span {
                    margin: 0 5px 0 5px !important;
                    color: $textcolor;
                    font-weight: 400 !important
                }
            }
        }
    }
}

Form {     
    .personel-infos {        
        border-radius: 7px;
        margin: 0px 0 15px 0;
        background: $bgcolor;         

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }
        .applicantImgWrapper{            
            width: 100vw;
            
            .applicantImg{                               
                width: 170px;
            }
        }
    }

    .other-infos {        
        border-radius: 7px;
        margin: 0 0 15px 0;
        background: $bgcolor;        

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }
    }

    .education-infos {        
        border-radius: 7px;
        margin: 0 0 15px 0;
        background: $bgcolor;        

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }
    }

    .doc-status {
        border: 1px solid $bgcolor;
        border-radius: 7px;
        margin: 0 0 15px 0;
        background-color: $bgcolor;

        h3 {
            color: $base !important;
            font-size: 24px !important;
        }
    }

    Button {
        border-radius: 4px !important;
    }

    .selectedTypeWrapper{
        .textUniversity{

        }
    }
}

.no-data-applicantui {
    // border: 1px solid $bgcolor;
    border-radius: 7px;
    background: $bgcolor;
    // box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    margin: 0;
    padding: 15px 5px 15px 5px;
    text-align: center;

    h5 {
        color: $textcolor;
        font-weight: 600;
    }

    p {
        color: $base;
        font-weight: 600;
        text-align: justify;
    }

    img {
        width: 200px;
        height: auto;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .applicant-ui-wrapper {
        h3 {
            font-size: 16px !important;
        }

        .personel-infos {
            padding: 10px !important;

            h3 {
                font-size: 16px !important;
            }

            h5 {
                font-size: 12px !important;
            }

            .image-wrapper {                
                width: 100%;
                height: 150px;
                text-align: center;

                img {
                    width: 50%;
                    margin-top: 0px;
                    border-radius: 2px;
                }
            }

            .personel-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .passport-infos {
            padding: 10px !important;

            h3 {
                font-size: 16px !important;
            }

            .passport-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .diplom-infos {
            padding: 10px !important;

            h3 {
                font-size: 16px !important;
            }

            .diplom-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .education-infos {
            padding: 10px !important;

            h3 {
                font-size: 16px !important;
            }

            .education-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 12px;
                    }
                }
            }

            // select university
            .textUniversity {                        
                font-size: 14px;
                width: 90%;
            }
            .denyUniversity{
                width: 10%;
            }
        }
    }

    Form {
        .personel-infos {

            h3 {
                font-size: 20px !important;
            }
        }

        .other-infos {

            h3 {
                font-size: 20px !important;
            }
        }

        .education-infos {

            h3 {
                font-size: 20px !important;
            }
        }

        .doc-status {

            h3 {
                font-size: 20px !important;
            }
        }

        Button {
            font-size: 12px !important;
        }
    }

    .no-data-applicantui {
        padding: 10px 5px 10px 5px;

        h5 {
            font-size: 14px !important;
        }

        p {
            font-size: 12px !important;
        }


        img {
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 768px) and (min-width: 426px) {
    .applicant-ui-wrapper {
        h3 {
            font-size: 20px !important;
        }

        .personel-infos {
            padding: 10px !important;

            h3 {
                font-size: 20px !important;
            }

            .image-wrapper {
                height: 150px;

                img {
                    margin-top: 0px;
                    border-radius: 2px;
                }
            }

            .personel-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 13px;
                    }
                }
            }
        }

        .passport-infos {
            padding: 10px !important;

            h3 {
                font-size: 20px !important;
            }

            .passport-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 13px;
                    }
                }
            }
        }

        .diplom-infos {
            padding: 10px !important;

            h3 {
                font-size: 20px !important;
            }

            .diplom-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 13px;
                    }
                }
            }
        }

        .education-infos {
            padding: 10px !important;

            h3 {
                font-size: 20px !important;
            }

            .education-text {
                p {
                    font-size: 14px;

                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    Form {
        .personel-infos {

            h3 {
                font-size: 22px !important;
            }
        }

        .other-infos {

            h3 {
                font-size: 22px !important;
            }
        }

        .education-infos {

            h3 {
                font-size: 22px !important;
            }
        }

        .doc-status {

            h3 {
                font-size: 22px !important;
            }
        }
        Button {
            font-size: 13px !important;
        }
    }

    .no-data-applicantui {
        padding: 10px 5px 10px 5px;

        h5 {
            font-size: 22px !important;
        }

        p {
            font-size: 14px !important;
        }

        img {
            width: 200px;
            height: auto;
        }
    }
}
