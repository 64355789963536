$color: #f5f5f5;
$color1: #2f54eb;
$color2: #a0d911;
$color3: #595959;
$bgcolor:#F8F8FB;
$textcolor: #434343;
$textcolor2:#595959;

.ant-divider.header {
    color: $color1 !important;
    font-size: 24px !important;
}

// export excel
.excel-export-wrapper {
    h5 {
        color: $textcolor2;
        font-weight: 600 !important;
    }

    .data-picker-wrapper {
        .ant-picker {
            border-radius: 4px;
        }

        Button {
            border-radius: 4px;
            width: 147px;
        }

        .ant-picker-panel-container {
            border-radius: 7px !important;
        }

    }
}

Form {
    // .ant-divider {
    //     color: $color1;
    // }

    Link {
        text-decoration: none !important
    }

    a {
        text-decoration: none !important;
    }
}