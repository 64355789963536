.name_link {
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
    color: #2d8cf0;
  }
}

.arrow-btn{
  color: #555;
  cursor: pointer;
  &:hover {
    color: #2d8cf0;
  }
}