$base:#002355;
$baseHover:#000739;
$color: #f5f5f5;
$color1: #2f54eb;
$color2: #a0d911;
$color3: #595959;
$bgcolor:#F8F8FB;
$textcolor: #434343;
.ant-layout-sider {
    height: 100vh !important;
    position: sticky;
    margin-top: 0;
}
.logo {

    h3 {
        text-align: center;
        color: $color;
        font-size: 22px;
        height: 60px;
        margin: 0;
        padding: 0;
        padding-top: 14px;
    }
}
// .ant-card-body {
//     padding: 25px 15px 25px 15px !important;
// }
.site-layout-background{
    background:$base !important;
}
.ant-menu-item-selected{
    background:$color !important;
}
