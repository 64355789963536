$base:#002355;
$baseHover:#000739;
$color: #f5f5f5;
$color1: #2f54eb;
$color2: #a0d911;
$color3: #595959;
$bgcolor:#fff;
$textcolor: #434343;

.edit-user-wrapper {    
    border-radius: 7px;
    background: $bgcolor;;    
    padding: 15px;

    h3 {
        color: $base;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .edit-user-wrapper {
        padding: 10px;

        h3 {
            font-size: 20px;
        }

        Button {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 768px) and (min-width: 426px) {
    .edit-user-wrapper {
        padding: 10px;

        h3 {
            font-size: 22px;
        }

        Button {
            font-size: 13px;
        }

    }
}