$base:#002355;
$baseHover:#000739;
$color: #f5f5f5;
$color1: #2f54eb;
$color2: #a0d911;
$color3: #595959;
$bgcolor:#F8F8FB;
$textcolor: #434343;


.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: $color;
}

.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  border-radius: 35px;
  max-width: 1000px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
  margin: 0 auto;
}

#login-form {
  flex: 1 0 100%;
  max-width: 580px;
  height: 500px;
  width: 100%;
  padding: 60px;

  .login-form-username {
    background-color: transparent;
    height: 42px;
    border-radius: 4px;
  }

  .login-form-password {
    height: 42px;
    border-radius: 4px;
  }

  .login-form-button {
    width: 100%;
    border-radius: 2px;
  }

  h3 {    
    margin-bottom: 30px;
  }

  .form-title {
    color: $base;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
  }

  .ant-input-affix-wrapper {
    padding: 12px 15px;
  }

  .registr-link {
    a {
      color: $base;
      font-weight: 600;

      svg {
        color: $textcolor !important;
        font-size: 16px;
      }
    }

    :hover {
      color: $baseHover;
      text-decoration: underline !important;
    }

  }
}

#login-form .ant-form-item-label>label.ant-form-item-required::before {
  display: none;
}

.ant-form-item-control-input-content {
  text-align: left;
}

#login-form .ant-btn {
  height: 38px;
  letter-spacing: 1px;
  background-color: $color1;
  border-radius: 2px;
}

#login-form .ant-btn:focus {
  background-color: $base;
}

.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: transparent;
}

.illustration-wrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }

  .illustration-wrapper {
    display: none;
  }

  #login-form {
    max-width: 100%;
  }
}

// registration
.registration-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: $color;

  #registration-form {
    max-width: 480px;
    width: 100%;
    padding: 30px;
    margin: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;
    background:white;

    .registration-image {
      text-align: center;

      p {
        color: #bfbfbf;
      }
    }

    .registration-form-number {
      width: 100%;
      height: 42px;
      border-radius: 4px;
    }

    .registration-form-button {
      width: 100%;
      height: 36px;
      border-radius: 4px;
      background: $color1;
    }

    .registration-form-back {
      height: 36px;
      border-radius: 4px;
      background: transparent;
      font-weight: 600;
      color: $base;

      :hover {
        text-decoration: underline !important;
      }
    }
  }

}

// reset 
.reset-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: $color;

  #reset-form {
    max-width: 480px;
    width: 100%;
    padding: 30px;
    margin: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    background:white;

    .reset-image {
      text-align: center;

      p {
        color: #bfbfbf;
      }
    }

    .reset-form-number {
      width: 100%;
      height: 42px;
      border-radius: 4px;
    }

    .reset-form-button {
      width: 100%;
      height: 36px;
      border-radius: 4px;
      background: $color1;
    }

    .reset-form-back {
      height: 36px;
      border-radius: 4px;
      background: transparent;
      font-weight: 600;
      color: $color1;

      :hover {
        text-decoration: underline !important;
      }
    }
  }

}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .navbar-brand{
    width: 120px;
    img{
      width: 100%;
      object-fit: contain;
      height: auto !important;
    }
  }
  .login-page {
    width: 100%;

    h3 {
      font-size: 19px !important;
      line-height: 1.6;
    }

    .login-form-username {
      width: 100% !important;
      height: auto;
      border-radius: 4px;
    }

    .login-form-password {
      height: 42px;
      border-radius: 4px;
    }

    .login-form-button {
      width: 100%;
      border-radius: 2px;
    }
  }
}