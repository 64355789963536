$base:#002355;
$baseHover:#000739;
$color: #f5f5f5;
$color1: #2f54eb;
$color2: #a0d911;
$color3: #595959;
$bgcolor:#F8F8FB;
$textcolor: #434343;

// form css
.table{    
    td.pivoted{
        padding: 5px 5px 0px 5px;             
    }
    tbody, td, tfoot, th, thead, tr {
        border: 1px solid #f0f0f0 !important;
    }      
}

// switch
.ant-switch-checked {
    background-color: $color2 !important;
}
.ant-switch {
   
}

.ant-layout {
    height: auto !important;
    position: relative;
    background-color: #fff !important;
}

.ant-layout-sider {
    position: sticky !important;
    top: 0 !important;
    left: 0 !important;
    height: 100vh;
    min-width: 0;
    background: #001529;
    transition: all 0.2s;
}

.sc-bdvvtL {
    position: sticky !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 999;
}

a {
    text-decoration: none !important;
}