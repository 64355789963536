$color1: #fff;
$color11: #f0f0f0;
$color2:#03045e;
$color3:#096dd9;
$bgcolor:#F8F8FB;
$textcolor: #434343;
$textcolor2: #000;


.statistics {
    // padding: 15px;

    .statics-wrapper {
        .card-statics {
            background-color: $color1 !important;
            margin-bottom: 15px !important;
            // height: 200px;
            border: 1px solid $color11;
            border-radius: 7px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            cursor: pointer;

            .ant-card-head {
                border: 0 !important;
            }

            .statics-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h4,
                .icon {
                    font-weight: 600;
                    font-size: 24px;
                    color: $textcolor;
                }

                h2,
                .icon-main {
                    font-weight: 600;
                    font-size: 34px;
                    color: $textcolor;
                }
            }

            p {
                color: $textcolor !important;
            }

            .statics-number {
                text-align: start;
                display: block;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        color: $textcolor;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }

        :hover {
            .statics-title {

                h4,
                .icon {
                    color: $color3;
                    transition: all 0.3s;
                }

                h2,
                .icon-main {
                    color: $color3;
                    transition: all 0.3s;
                }

                h4 {
                    Badge {
                        font-size: 44px !important;
                    }
                }
            }


            .statics-number {


                ul {


                    li {
                        color: $color2;
                    }
                }
            }
        }
    }





}